import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';

import { Background } from '../../../../shared/components/background';
import { Content } from '../../../../shared/components/content';
import { Grid } from '../../../../shared/components/grid';
import { Icon } from '../../../../shared/components/icon';
import { Section } from '../../../../shared/components/section';
import { Spacing } from '../../../../shared/components/spacing';
import { TimerProgress } from '../../../../shared/composites/timerProgress';
import { QUERY_STRING_PARAMS } from '../../../../shared/config';
import { Logger } from '../../../../shared/logger';
import Bad from '../../assets/images/bad.svg';
import { Header } from '../../composites/header';
import { Waves } from '../../composites/waves';
import { getChromeExtId } from '../../core/utils';
import ExtensionStore from '../../stores/extension';
import { SiteDirectory } from '../siteDirectoy/siteDirectory';

export const Interceptor: React.FunctionComponent = observer(() => {
  const extensionStore = useContext(ExtensionStore);
  const location = useLocation();

  const buildStopRedirectUrl = (
    siteUrl: string,
    stopRedirectKey: string
  ): string => {
    // determine which separator we need to use
    const separator = siteUrl.includes(`?`) ? '&' : '?';
    const stopRedirectQueryString = `${separator}${stopRedirectKey}=true`;
    // we need to check if the url has an # in it, if so we need to insert the query string before it
    const hashIndex = siteUrl.indexOf('#');
    const redirectUrl =
      hashIndex > 0
        ? `${siteUrl.slice(
            0,
            hashIndex
          )}${stopRedirectQueryString}${siteUrl.slice(hashIndex)}`
        : `${siteUrl}${stopRedirectQueryString}`;
    return redirectUrl;
  };

  const onContinue = (): void => {
    const urlParams = new URLSearchParams(location.search);
    const urlEncoded = urlParams.get(QUERY_STRING_PARAMS.urlKey);
    let url = urlEncoded ? decodeURIComponent(urlEncoded) : null;
    if (!url) {
      Logger.error(`Unable to decode URL: ${location.search}`);
      return;
    }
    // the interceptor always redirect to validation.
    url = buildStopRedirectUrl(url, QUERY_STRING_PARAMS.stopRedirectKey);
    const navigateToQueryString = `${
      QUERY_STRING_PARAMS.navigateTo
    }=${encodeURIComponent(url)}`;
    const validationUrl =
      urlEncoded &&
      `chrome-extension://${getChromeExtId()}/ui/index.html#validation/calibration?${navigateToQueryString}`;
    if (validationUrl) {
      window.location.replace(validationUrl);
    }
  };

  const onFocus = (): void => {
    extensionStore.connectToExtension();
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('focus', onFocus);
    }
    return (): void => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Vision Project`}</title>
      </Helmet>
      <Waves side="both" />
      <Section>
        <Background />
        <Content>
          <Spacing size={100} unit="px" />
          <Header />
          <Spacing size={100} unit="px" />
          <Icon height={64} width={64}>
            <Bad />
          </Icon>
          <Spacing size={20} unit="px" />
          <h1>Oops, this site is not part of the study</h1>
          <Spacing size={20} unit="px" />
          <Grid container>
            <Grid item size={40}>
              <p>
                If you continue to this site, the study will pause. You will not
                be able to complete the study until you return to one of the
                sites below.
              </p>
              <Spacing size={40} unit="px" />
              <p>
                <a onClick={onContinue}>Continue anyway</a>
              </p>
            </Grid>
            <Grid item size={60}>
              <div className="centered">
                <TimerProgress
                  duration={Math.round(extensionStore.duration / 60000)}
                  progress={extensionStore.progress}
                  label="Browse more sites to complete this study"
                />
              </div>
            </Grid>
          </Grid>
        </Content>
      </Section>
      <Section className="white-background" freeHeight>
        <Background />
        <SiteDirectory openLinkOnNewTab={false} />
      </Section>
    </React.Fragment>
  );
});
