import React from 'react';
import styled from '@emotion/styled';

const IframeStyled = styled.iframe`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;
export const PrivacyPolicy: React.FunctionComponent = () => {
  return (
    <IframeStyled src="https://docs.google.com/document/d/e/2PACX-1vRoaS_siLYLyWw1FRWM3sh1IETXoBmqIsU23LtfQf3vOZ_tc0VYfMzv_n6PpSMmlAbtmeLFGF5pzAWQ/pub?embedded=true" />
  );
};
