export interface Message {
  pxyz: {
    data:
      | TrackingReadyData
      | ProgressData
      | StudyDetailsRequestData
      | CompletionData
      | RemoveCookieData
      | ActiveData
      | GeneralInfoData
      | CameraPermissions;
  };
}

export interface TrackingReadyData {
  eyeTrackingIsReady: boolean;
}

export interface ProgressData {
  duration: number;
  progress: number;
}

export interface StudyDetailsRequestData {
  request: string;
}

export interface CompletionData {
  code: string;
}

export interface RemoveCookieData {
  removeCookie: boolean;
}

export interface ActiveData {
  isActive: boolean;
}

export interface GeneralInfoData {
  wasExtendedOnLaunch: boolean;
}

export interface CameraPermissions {
  cameraPermissions: string;
}

export const onMessageProgressData = (
  e: Message,
  callback: (data: ProgressData) => void
): void => {
  if (e.pxyz && (e.pxyz.data as ProgressData).progress !== undefined) {
    callback(e.pxyz.data as ProgressData);
  }
};

export const onMessageCameraPermissions = (
  e: Message,
  callback: (data: CameraPermissions) => void
): void => {
  if (
    e.pxyz &&
    (e.pxyz.data as CameraPermissions).cameraPermissions !== undefined
  ) {
    callback(e.pxyz.data as CameraPermissions);
  }
};

export const onMessageStudyDetailsRequest = (
  e: Message,
  callback: (data: StudyDetailsRequestData) => void
): void => {
  if (
    e.pxyz &&
    (e.pxyz.data as StudyDetailsRequestData).request !== undefined
  ) {
    callback(e.pxyz.data as StudyDetailsRequestData);
  }
};

export const onMessageCompletionData = (
  e: Message,
  callback: (data: CompletionData) => void
): void => {
  if (e.pxyz && (e.pxyz.data as CompletionData).code !== undefined) {
    callback(e.pxyz.data as CompletionData);
  }
};

export const onMessageActiveData = (
  e: Message,
  callback: (data: ActiveData) => void
): void => {
  if (e.pxyz && (e.pxyz.data as ActiveData).isActive !== undefined) {
    callback(e.pxyz.data as ActiveData);
  }
};

export const onMessageGetInfo = (
  e: Message,
  callback: (data: GeneralInfoData) => void
): void => {
  if (e.pxyz && (e.pxyz.data as GeneralInfoData) !== undefined) {
    callback(e.pxyz.data as GeneralInfoData);
  }
};
