import React from 'react';
import styled from '@emotion/styled';

import { ReactProps } from '../../../shared/core/interfaces';

const CheckboxStyled = styled.div`
  position: relative;
  padding: 11px;
  width: 0;
  height: 0;
  border-radius: 99px;
  margin-left: 35px;
  border: 2px solid rgba(255, 255, 255, 1);
  outline: 0;
  &:hover {
    cursor: pointer;
  }
`;

const TickStyled = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  top: 2px;
  left: 2px;
  border-radius: 99px;
  opacity: 0;

  svg {
    fill: #fff;
  }

  &.ticked {
    opacity: 1;
    background-color: rgb(237, 25, 98);
  }
`;

interface CheckboxProps {
  ticked: boolean;
  itemKey: string;
  clickHandler?(key: string): void;
  className?: string;
}

export const Checkbox: React.FunctionComponent<CheckboxProps & ReactProps> = (
  props: CheckboxProps & ReactProps
) => {
  const { ticked, clickHandler, itemKey, children } = props;
  const handleClick = (): void => {
    if (clickHandler) clickHandler(itemKey);
  };
  const tickedClass = ticked ? 'ticked' : '';
  return (
    <CheckboxStyled
      role="checkbox"
      aria-checked={ticked}
      aria-label="Select"
      tabIndex={0}
      onKeyPress={handleClick}
      onClick={handleClick}
    >
      <TickStyled className={tickedClass}>{children}</TickStyled>
    </CheckboxStyled>
  );
};
