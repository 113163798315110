import 'mobx-react/batchingForReactDom';
import 'toasted-notes/src/styles.css';

import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import toaster from 'toasted-notes';

import { GlobalStyles } from '../../shared/components/globalStyles';
import { Logger } from '../../shared/logger';
import { Error } from './controllers/error/error';
import { Splash } from './controllers/splash/splash';
import { RouteItem } from './core/interfaces';
import { routes } from './core/routes';
import {
  getChromeExtId,
  isUnauthorized,
  needsPrivacyRedirect,
} from './core/utils';
import VpCookieStore from './stores/vpCookie';
import ExtensionStore from './stores/extension';
import FaqHelp from './assets/images/faq-help.svg';
import { NeedsReload } from './controllers/error/needsReload';

const StyledToast = styled.div`
  color: #000;
`;

const faqBlackList = ['faq'];
const FaqContainer = styled.a`
  position: fixed;
  bottom: 28px;
  right: 28px;
  z-index: 1;
`;

const App: React.FunctionComponent = observer(() => {
  const vpCookieStore = useContext(VpCookieStore);
  const extensionStore = useContext(ExtensionStore);
  const [splashHidden, setSplashHidden] = useState<boolean>(false);
  let content;

  const needsReload =
    extensionStore.wasExtendedOnLaunch || window.screen['isExtended'];

  const notification = (message: string): void => {
    toaster.notify(<StyledToast>{message}</StyledToast>, {
      type: 'error',
      duration: null,
      position: 'top-right',
    });
  };

  useEffect(() => {
    if (location.pathname === '/') {
      (async (): Promise<void> => {
        setTimeout(async () => {
          await vpCookieStore.setAuthorised();
          setSplashHidden(true);
        }, 1500);
      })();
    } else {
      vpCookieStore.loadAuthorisation();
    }

    if (!['/', '/completion'].includes(location.pathname)) {
      if (vpCookieStore.authorised) {
        //Set timeout before checking extension state as it takes a bit of time to connect to the extension
        //and exchange messages go check extension state
        setTimeout(() => {
          if (
            !getChromeExtId() ||
            ((!extensionStore.connected || !extensionStore.active) &&
              !extensionStore.connecting)
          ) {
            notification(
              'The chrome extension is not responding. Please reinstall it and refresh the page.'
            );
          }
        }, 1000);
      } else {
        setSplashHidden(true);
      }
    }
  }, []);

  const onSplashHides = (): void => {
    setSplashHidden(true);
  };

  if (vpCookieStore.authorised === undefined) {
    Logger.info(
      `authorisation process starting \n path:${window.location.href}`
    );

    content = <Splash label="" />;
  }

  if (vpCookieStore.authorised === true && !splashHidden) {
    Logger.info('authorisation granted - hidding splash');
    content = (
      <Splash label="" className="hide" transitionHandler={onSplashHides} />
    );
  }

  const faqIcon = (routeItem: RouteItem): React.ReactFragment => {
    return (
      !faqBlackList.includes(routeItem.id) && (
        <FaqContainer href="/faq" target="_blank" rel="noopener noreferrer">
          <FaqHelp />
        </FaqContainer>
      )
    );
  };

  if (vpCookieStore.vpDebugMode || splashHidden) {
    const renderComponent = routes.map(
      (routeItem: RouteItem): JSX.Element => {
        const Component = routeItem.component;
        return (
          <Route
            key={routeItem.id}
            path={routeItem.path}
            render={(): React.ReactNode => {
              if (
                isUnauthorized(vpCookieStore.authorised) &&
                !routeItem.isPublic
              ) {
                return <Error type="auth" />;
              } else if (
                needsPrivacyRedirect(vpCookieStore.privacyAccepted, routeItem)
              ) {
                return <Redirect to="/privacy" />;
              }
              return (
                <React.Fragment>
                  {faqIcon(routeItem)}
                  <Component />
                </React.Fragment>
              );
            }}
          />
        );
      }
    );

    content = (
      <Router>
        <React.Fragment>
          <Switch>{renderComponent}</Switch>
        </React.Fragment>
      </Router>
    );
  }

  // Push to reload page if screen extended
  if (!vpCookieStore.vpDebugMode && needsReload) {
    content = <NeedsReload type="wasExtended" />;
  }

  if (vpCookieStore.vpDebugMode) {
    vpCookieStore.acceptPrivacy();
  }

  return (
    <React.Fragment>
      <GlobalStyles />
      {content}
    </React.Fragment>
  );
});
const app = document.getElementById('app');
ReactDOM.render(<App />, app);
