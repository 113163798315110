import React from 'react';
import styled from '@emotion/styled';

import Logo from '../assets/images/logo.svg';
import { Icon } from '../components/icon';
import { Spacing } from '../components/spacing';

const StyledProject = styled.div`
  display: inline-block;
  font-family: 'Avenir', helvetica, arial, sans-serif;
  font-size: 1.2em;
  letter-spacing: 4px;
  font-weight: 300;
  text-transform: uppercase;
`;

interface HeaderSmallProps {
  label?: string;
}

export const HeaderSmall: React.FunctionComponent<HeaderSmallProps> = (
  props: HeaderSmallProps
) => {
  const { label } = props;
  return (
    <>
      <div className="centered">
        <Icon height={56} width={56}>
          <Logo />
        </Icon>
      </div>
      <Spacing size={20} unit={'px'} />
      <div className="centered">
        <StyledProject>{label}</StyledProject>
      </div>
    </>
  );
};
