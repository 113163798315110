import React from 'react';
import { getPathFromRouteId } from '../../core/routes';
import { ErrorTemplate } from '../../composites/errorTemplate';
interface ErrorProps {
  type: 'auth';
}

const onSubmit = (): void => {
  window.location.href = getPathFromRouteId('faq');
};

export const Error: React.FunctionComponent<ErrorProps> = () => {
  return (
    <ErrorTemplate
      headerSmall={'STUDY ELEMENT MISSING'}
      title={'The study is by invitation only'}
      content={`Were you invited by a task provider? if so, click the link provided
              by your task provider for direct access to the study and to get
              started.`}
      button={{
        label: 'Help Page',
        disabled: false,
        clickHandler: onSubmit,
      }}
    />
  );
};
