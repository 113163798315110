import styled from '@emotion/styled';
import * as React from 'react';

const ButtonStyled = styled.button<ButtonProps>`
  padding: 10px 24px;
  top: 10px;
  width: auto;
  min-width: ${({ minWidth }): string => minWidth || 'inherited'};
  border: 1px solid ${({ invert }): string => (invert ? '#000' : '#fff')};
  border-radius: 3px;
  background: transparent;
  font-size: 16px;
  color: ${({ invert }): string => (invert ? '#000' : '#fff')};
  outline: 0;
  &:hover {
    color: ${({ invert }): string => (invert ? '#000' : '#2f1c3b')};
    cursor: pointer;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: ${({ invert }): string =>
      invert ? '0px 8px 8px rgba(0,0,0,.25)' : 'none'};
  }
  :hover span {
    color: ${({ invert }): string => (invert ? '#000' : '#2f1c3b')};
  }
  &.disabled {
    opacity: 0.4;
    &:hover {
      border: 1px solid ${({ invert }): string => (invert ? '#000' : '#fff')};
      color: ${({ invert }): string => (invert ? '#000' : '#fff')};
      background-color: transparent;
      cursor: default;
    }
  }
`;

interface ButtonProps {
  label: string | React.ReactNode;
  disabled?: boolean;
  invert?: boolean;
  minWidth?: string;
  clickHandler?(): void;
}

export const Button: React.FunctionComponent<ButtonProps> = (
  props: ButtonProps
) => {
  const { label, disabled, clickHandler, invert, minWidth } = props;
  const className: string = disabled ? 'disabled' : '';
  const onClick = (): void => {
    if (clickHandler && !disabled) clickHandler();
  };

  return (
    <ButtonStyled
      {...{ invert, label, disabled, minWidth }}
      onClick={onClick}
      className={className}
    >
      {label}
    </ButtonStyled>
  );
};
