import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { ErrorTemplate } from '../../composites/errorTemplate';
import ExtensionStore from '../../stores/extension';

interface NeedsReloadProps {
  type: 'wasExtended';
}

export const NeedsReload: React.FC<NeedsReloadProps> = ({ type }) => {
  const extensionStore = useContext(ExtensionStore);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const contentConfig = {
    wasExtended: {
      header: 'EXTERNAL MONITOR DETECTED',
      title: 'Something went wrong',
      description: (
        <span>
          We have detected multiple monitors. If you are using a laptop, please
          ensure that any additional monitors are disconnected. If you are using
          a desktop, please ensure that only one monitor is connected and that
          the webcam is mounted directly above it.
        </span>
      ),
      button: {
        label: 'Retry',
        clickHandler: () => {
          setLoading(true);
          extensionStore.requestReload();
          history.push('/');
        },
      },
    },
  };

  const content = contentConfig[type];

  return (
    <ErrorTemplate
      headerSmall={content.header}
      title={content.title}
      content={content.description}
      button={{
        label: loading ? 'Loading...' : content.button.label,
        disabled: loading,
        clickHandler: content.button.clickHandler,
      }}
    />
  );
};
