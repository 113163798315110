import React from 'react';
import { QUERY_STRING_PARAMS } from '../../../../shared/config';
import { ErrorTemplate } from '../../composites/errorTemplate';
import { getChromeExtId } from '../../core/utils';

const onSubmit = (): void => {
  const params = new URLSearchParams(location.search);
  const chromeExtensionId = getChromeExtId();

  if (!chromeExtensionId) return;

  let cameraUrl = `chrome-extension://${chromeExtensionId}/ui/index.html#/camera-distance`;

  if (params.has(QUERY_STRING_PARAMS.navigateTo)) {
    const urlEncoded = params.get(QUERY_STRING_PARAMS.navigateTo);
    cameraUrl += `?${QUERY_STRING_PARAMS.navigateTo}=${urlEncoded}&setup=true`;
  }
  window.location.href = cameraUrl;
};

export const OutOfView: React.FunctionComponent = () => {
  return (
    <ErrorTemplate
      headerSmall={'EYE TRACKING ISSUE'}
      title={'Are you still there?'}
      content={`We’re having trouble conducting eye tracking. You may have moved
              away from the camera or your set up conditions have changed.
              Please update your eye tracking to continue the study.`}
      button={{
        label: 'Update Eye Tracking',
        disabled: false,
        clickHandler: onSubmit,
      }}
    />
  );
};
