import React from 'react';
import styled from '@emotion/styled';

import { Waves } from './waves';
import { Background } from '../../../shared/components/background';
import { Button } from '../../../shared/components/button';
import { Content } from '../../../shared/components/content';
import { Section } from '../../../shared/components/section';
import { Spacing } from '../../../shared/components/spacing';
import { HeaderSmall } from '../../../shared/composites/headerSmall';
import { ReactProps } from '../../../shared/core/interfaces';

const ContentStyled = styled.div`
  max-width: 400px;
  margin: auto;
`;

const LinkStyled = styled.a`
  margin: 0;
  text-decoration: underline;
`;

interface ErrorProps {
  headerSmall: string;
  title: string;
  content: string | JSX.Element;
  button: {
    label: string;
    disabled: boolean;
    clickHandler: () => void;
  };
  link?: {
    href: string;
    label: string;
  };
}

export const ErrorTemplate: React.FunctionComponent<ReactProps & ErrorProps> = (
  props: ReactProps & ErrorProps
) => {
  return (
    <Section>
      <Background />
      <Waves side="both" />
      <Content>
        <Spacing size={60} unit={'px'} />
        <HeaderSmall label={props.headerSmall} />
        <div className="centered">
          <Spacing size={40} unit={'px'} />
          <ContentStyled>
            <Spacing size={20} unit={'px'} />
            <h4>{props.title}</h4>
            <Spacing size={20} unit={'px'} />
            <div>{props.content}</div>
            <Spacing size={40} unit={'px'} />
            <Button
              label={props.button.label}
              disabled={props.button.disabled}
              clickHandler={props.button.clickHandler}
            />
            {props.link ? (
              <React.Fragment>
                <Spacing size={20} unit={'px'} />
                <LinkStyled href={props.link.href}>
                  {props.link.label}
                </LinkStyled>
              </React.Fragment>
            ) : null}
          </ContentStyled>
        </div>
      </Content>
    </Section>
  );
};
