import styled from '@emotion/styled';
import React, { useRef, useEffect } from 'react';
import { useState } from 'react';

import { Background } from '../../../../shared/components/background';
import { Content } from '../../../../shared/components/content';
import { Grid } from '../../../../shared/components/grid';
import { Icon } from '../../../../shared/components/icon';
import { Section } from '../../../../shared/components/section';
import { Spacing } from '../../../../shared/components/spacing';
import Minus from '../../assets/images/minus.svg';
import Plus from '../../assets/images/plus.svg';
import { Header } from '../../composites/header';
import { Waves } from '../../composites/waves';
import { FAQCategories, FAQContent } from '../../core/data';

const Menu = styled.div`
  position: sticky;
  top: 5px;
  width: 100%;
  background: linear-gradient(
    transparent 0%,
    rgba(255, 255, 255, 0.1) 0.5%,
    transparent 100%
  );
  padding: 30px 0;
  li {
    a {
      font-style: normal;
      font-size: 1em;
      line-height: 140%;
      text-decoration: none;
      font-weight: 300;
      &.active {
        text-decoration: underline;
      }
    }
    margin-bottom: 30px;
  }
`;

const Category = styled.div`
  padding: 0 70px;
`;

const Title = styled.div`
  margin: 0;
  font-family: 'Avenir', helvetica, arial, sans-serif;
  font-weight: normal;
  letter-spacing: 4px;
  font-size: 1.2em;
  text-transform: uppercase;
  text-transform: uppercase;
`;

const Accordion = styled.div`
  position: relative;
  padding-left: 35px;
  a {
    text-decoration: none;
    user-select: none;
  }
`;

const AccordionContent = styled.div`
  max-height: 0;
  overflow-y: hidden;
  &.active {
    max-height: 100%;
  }
`;

const AccordionIcon = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 99px;
  background-color: #fff;
  left: 0;
  top: 0;
`;

const FAQCategoriesKeys = Object.keys(FAQCategories);

export const FAQ: React.FunctionComponent = () => {
  const [active, setActive] = useState<string>('');
  const elementRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const elementId = location.hash.replace('#', '');
    if (elementRef && FAQCategoriesKeys.includes(elementId)) {
      // find the position of the element to scroll to it.
      // using `scrollIntoView` will cause the page to scroll too far (not sure why),
      // so we need to use window.scrollTo
      const element = document.getElementById(elementId);
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset;

        window.scrollTo({
          top: y,
          behavior: 'smooth',
        });
      }
    }
  }, [elementRef, location.hash]);

  const categories = Object.entries(FAQCategories).map(([key, value]) => {
    return (
      <li key={`link-${key}`}>
        <a
          href={`#${key}`}
          className={`#${key}` === window.location.hash ? 'active' : ''}
        >
          {value}
        </a>
      </li>
    );
  });
  const sections = Object.entries(FAQCategories).map(([key, value]) => {
    const categoryContent = FAQContent.filter((value) => {
      return value.category === key;
    });
    const content = categoryContent.map((value, index) => {
      const itemKey = `item-${key}-${index}`;
      const icon = active === itemKey ? <Minus /> : <Plus />;
      return (
        <div key={itemKey} ref={elementRef} id={itemKey}>
          <Accordion>
            <a
              onClick={(): void => {
                if (active === itemKey) {
                  setActive('');
                } else {
                  setActive(itemKey);
                }
              }}
            >
              <AccordionIcon>
                <Icon width={20} height={20} stroke="#000">
                  {icon}
                </Icon>
              </AccordionIcon>
              {value.title}
            </a>
            <AccordionContent className={active === itemKey ? 'active' : ''}>
              <Spacing size={20} unit="px" />
              {value.content}
            </AccordionContent>
          </Accordion>
          <Spacing size={50} unit="px" />
        </div>
      );
    });
    return (
      <Category id={key} key={`category-${key}`}>
        <Title>{value}</Title>
        <Spacing size={30} unit="px" />
        {content}
      </Category>
    );
  });
  return (
    <React.Fragment>
      <Background />
      <Waves side="both" />
      <Section freeHeight>
        <Content>
          <Header />
          <Spacing size={130} unit="px" />
          <h1>Need some help?</h1>
          <Spacing size={20} unit="px" />
          <p>
            Here are our most frequently asked questions. Can’t find what you’re
            looking for? Send a message.
          </p>
          <Spacing size={20} unit="px" />
          <Grid container>
            <Grid item size={30}>
              <Menu>
                <ul>{categories}</ul>
              </Menu>
            </Grid>
            <Grid item size={70}>
              {sections}
            </Grid>
          </Grid>
        </Content>
      </Section>
    </React.Fragment>
  );
};
