export const DEFAULT_DURATION = 10;
export const EXTENSION_LINK =
  process.env['EXTENSION_LINK'] ||
  'https://chrome.google.com/webstore/detail/vision-project/dlhjgdppociflmngoephclpekjpkadph';
export const FAVICON_API =
  process.env['FAVICON_API'] || 'https://icons.duckduckgo.com/ip2/';
export const SITE_PAGE_SIZE: number = process.env['SITE_PAGE_SIZE']
  ? parseInt(process.env['SITE_PAGE_SIZE'])
  : 45;
export const VP_API_URL =
  process.env['VP_API_URL'] || 'https://vision-project-dev.appspot.com';
export const PLATFORMS: string[] = process.env['PLATFORMS']
  ? process.env['PLATFORMS'].split(',')
  : ['mturk', 'clickworker', 'pxyz'];
export const FAQ_FEED =
  process.env['FAQ_FEED'] ||
  'https://spreadsheets.google.com/feeds/cells/1eeN_YuAAV7cM1gqI67ER1XtWpXvIZH10YwMTei3PW3o/1/public/full?alt=json';

// https://monetize.xandr.com/dmp/segments/23798053
export const SEGMENT_AD_TARGETING_ID = '23798053';
