import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { observer } from 'mobx-react';

import Logo from '../../../../shared/assets/images/logo.svg';
import { Background } from '../../../../shared/components/background';
import { Button } from '../../../../shared/components/button';
import { Content } from '../../../../shared/components/content';
import { Grid } from '../../../../shared/components/grid';
import { Icon } from '../../../../shared/components/icon';
import { Section } from '../../../../shared/components/section';
import { Spacing } from '../../../../shared/components/spacing';
import { HeaderSmall } from '../../../../shared/composites/headerSmall';
import Chat from '../../assets/images/chat.svg';
import { Bullet } from '../../components/bullet';
import { NavigationNext } from '../../composites/navigationNext';
import { Waves } from '../../composites/waves';
import { completionItems } from '../../core/data';
import { FeatureItem } from '../../core/interfaces';
import { clearCache } from '../../core/vpApi';
import ExtensionStore from '../../stores/extension';
import VpCookieStore from '../../stores/vpCookie';

gsap.registerPlugin(ScrollToPlugin);

const CompletionStyled = styled.div`
  width: 650px;
  margin: auto;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  overflow-y: scroll;
  flex-direction: column;
`;

const WrapperLogo = styled.div`
  display: inline-block;
  width: 48px;
  height: 48px;
  background-color: #969397;
  border-radius: 99px;
  padding: 6px 2px;
`;

const WrapperChat = styled.div`
  display: inline-block;
  width: 48px;
  height: 48px;
  background-color: #969397;
  border-radius: 99px;
  padding: 10px 6px;
`;

const WrapperIcon = styled.div`
  display: inline-block;
  width: 74px;
  height: 74px;
  border: 1px solid #fff;
  border-radius: 99px;
  padding: 12px;
`;

const WrapperFeedback = styled.div`
  padding: 20px;
  border-radius: 30px;
`;

const WrapperExtension = styled.div`
  max-width: 600px;
  text-align: center;
  margin: auto;
`;

export const Completion: React.FunctionComponent<{}> = observer(() => {
  const extensionStore = useContext(ExtensionStore);
  const vpCookieStore = useContext(VpCookieStore);

  let wrapper: HTMLDivElement | null;
  const codeSectionId = 'completionCodeSection';

  const onNavigationClick = (): void => {
    gsap.to(wrapper, {
      duration: 1,
      scrollTo: { y: `#${codeSectionId}` },
    });
  };

  const onSurveyClick = (): void => {
    const userId = vpCookieStore.userId;
    const surveyId = vpCookieStore.surveyId;
    const feedId = vpCookieStore.feedId;
    const surveyUrl = `https://www.surveymonkey.com/r/${surveyId}?uniquecode=${userId}&feed=${feedId}`;
    window.open(surveyUrl, '_blank');
  };

  const completions = completionItems.map(
    (item: FeatureItem, index: number): JSX.Element => {
      const ItemIcon = item.icon;
      return (
        <Grid item size={25} key={`feature-grid-item${index}`}>
          <div className="centered">
            <WrapperIcon>
              <Icon color="white" height={48} width={48}>
                <ItemIcon />
              </Icon>
            </WrapperIcon>
            <Spacing size={30} unit="px" />
            <h5>{item.title}</h5>
            <Spacing size={25} unit="px" />
            {item.descritpion}
          </div>
        </Grid>
      );
    }
  );

  useEffect(() => {
    clearCache(vpCookieStore.adCampaignId, vpCookieStore.userId);

    setTimeout(() => {
      extensionStore.requestCode();
    }, 1000);
  }, []);

  return (
    <Wrapper ref={(node): HTMLDivElement | null => (wrapper = node)}>
      <Section>
        <Background />
        <Waves side="right" />
        <Content>
          <HeaderSmall label="MISSION COMPLETE" />
          <Spacing size={100} unit="px" />
          <Grid container justifyContent="space-around">
            {completions}
          </Grid>
        </Content>
        <NavigationNext
          clickHandler={(): void => {
            onNavigationClick();
          }}
        />
      </Section>
      <Section
        id={codeSectionId}
        className="white-background"
        freeAlign
        freeHeight
      >
        <Background />
        <Waves side="left" />
        <Content>
          <Spacing size={80} unit="px" />
          <Grid container justifyContent="space-evenly">
            <Grid item size={60}>
              <Spacing size={50} unit="px" />
              <CompletionStyled>
                <p>Thanks for taking part in the Vision Project study.</p>
                <Spacing size={30} unit="px" />
                <Bullet label="COMPLETION CODE" text={extensionStore.code} />
              </CompletionStyled>
            </Grid>
            <Grid item size={25}>
              <WrapperFeedback>
                <div className="centered">
                  <WrapperChat>
                    <Icon height={28} width={28}>
                      <Chat />
                    </Icon>
                  </WrapperChat>
                  <Spacing size={20} unit="px" />
                  <p>Have 5 minutes to spare?</p>
                  <Spacing size={20} unit="px" />
                  <span>
                    We would love to hear from you. Let us know what you thought
                    of the study.
                  </span>
                  <Spacing size={20} unit="px" />
                  <Button
                    label="Feedback Survey"
                    disabled={false}
                    clickHandler={onSurveyClick}
                  />
                </div>
              </WrapperFeedback>
            </Grid>
          </Grid>
          <Spacing size={160} unit="px" />
          <div className="centered">
            <WrapperLogo>
              <Icon width={32} height={32}>
                <Logo />
              </Icon>
            </WrapperLogo>
            <Spacing size={30} unit="px" />
            <WrapperExtension>
              <p>How do I uninstall the Chrome Extension?</p>
              <Spacing size={30} unit="px" />
              <span>
                The Vision Project chrome extension has been automatically
                disabled. It will not conduct eye tracking or turn your webcam
                on until you complete another study.
                <br /> <br />
                If you wish to completely uninstall it, look for the Vision
                Project icon on the top right hand corner of your browser. Right
                click and select REMOVE FROM CHROME.
              </span>
              <Spacing size={30} unit="px" />
            </WrapperExtension>
            <Spacing size={80} unit="px" />
          </div>
        </Content>
      </Section>
    </Wrapper>
  );
});
