import React from 'react';
import styled from '@emotion/styled';

import { Icon } from '../../../shared/components/icon';
import ArrowDown from '../assets//images/arrow-down.svg';

const NextContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 86px;
  text-align: center;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

interface NavigationNextProps {
  clickHandler?: () => void;
}

export const NavigationNext: React.FunctionComponent<NavigationNextProps> = (
  props: NavigationNextProps
) => {
  const { clickHandler } = props;
  return (
    <NextContainer>
      <a
        onClick={(): void => {
          if (clickHandler) clickHandler();
        }}
      >
        <Icon width={64} height={64} color="transparent">
          <ArrowDown />
        </Icon>
      </a>
    </NextContainer>
  );
};
