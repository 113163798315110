import { Completion } from '../controllers/completion/completion';
import { FAQ } from '../controllers/faq/faq';
import { Home } from '../controllers/home/home';
import { Interceptor } from '../controllers/interceptor/interceptor';
import { Privacy } from '../controllers/privacy/privacy';
import { PrivacyPolicy } from '../controllers/privacy/privacyPolicy';
import { TermsOfUseAgreement } from '../controllers/privacy/termsOfUseAgreement';
import { PrivacyNotice } from '../controllers/privacy/privacyNotice';
import { Splash } from '../controllers/splash/splash';
import { TaskSummary } from '../controllers/taskSummary/taskSummary';
import { AdBlockerDetected } from '../controllers/error/adBlockedDetected';
import { OutOfView } from '../controllers/error/outOfView';
import { YouTubePremium } from '../controllers/error/youTubePremium';
import { EyeTrackingBlocked } from '../controllers/error/eyeTrackingBlocked';
import { RouteItem } from './interfaces';

export const routes: RouteItem[] = [
  {
    name: 'Task Summary',
    id: 'task-summary',
    path: '/task-summary',
    isPublic: false,
    privacyCheck: true,
    component: TaskSummary,
  },
  {
    name: 'Privacy',
    id: 'privacy',
    path: '/privacy',
    privacyCheck: false,
    isPublic: false,
    component: Privacy,
  },
  {
    name: 'Privacy Policy',
    id: 'privacy-policy',
    path: '/privacy-policy',
    privacyCheck: false,
    isPublic: true,
    component: PrivacyPolicy,
  },
  {
    name: 'Terms Of Use Agreement',
    id: 'terms-of-use-agreement',
    path: '/terms-of-use-agreement',
    privacyCheck: false,
    isPublic: true,
    component: TermsOfUseAgreement,
  },
  {
    name: 'Privacy Notice',
    id: 'privacy-notice',
    path: '/privacy-notice',
    privacyCheck: false,
    isPublic: true,
    component: PrivacyNotice,
  },
  {
    name: 'Interceptor',
    id: 'interceptor',
    path: '/interceptor',
    privacyCheck: true,
    isPublic: false,
    component: Interceptor,
  },
  {
    name: 'Splash',
    id: 'splash',
    path: '/splash',
    privacyCheck: false,
    isPublic: true,
    component: Splash,
  },
  {
    name: 'Completion',
    id: 'completion',
    path: '/completion',
    privacyCheck: true,
    isPublic: false,
    component: Completion,
  },
  {
    name: 'AdBlocker detected',
    id: 'ad-blocker-detected',
    path: '/ad-blocker-detected',
    privacyCheck: false,
    isPublic: false,
    component: AdBlockerDetected,
  },
  {
    name: 'Out Of View',
    id: 'out-of-view',
    path: '/out-of-view',
    privacyCheck: false,
    isPublic: false,
    component: OutOfView,
  },
  {
    name: 'Eye Tracking Blocked',
    id: 'eye-tracking-blocked',
    path: '/eye-tracking-blocked',
    privacyCheck: false,
    isPublic: false,
    component: EyeTrackingBlocked,
  },
  {
    name: 'YouTube Premium',
    id: 'youtube-premium',
    path: '/youtube-premium',
    privacyCheck: false,
    isPublic: true,
    component: YouTubePremium,
  },

  {
    name: 'FAQ',
    id: 'faq',
    path: '/faq',
    privacyCheck: false,
    isPublic: true,
    component: FAQ,
  },
  {
    name: 'Home',
    id: 'home',
    path: '/',
    privacyCheck: false,
    isPublic: false,
    component: Home,
  },
];

export const getPathFromRouteId = (id: string): string => {
  const route = routes.find((r) => r.id === id);
  if (!route) {
    return '/';
  }
  return route.path;
};
