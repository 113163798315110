import React from 'react';
import styled from '@emotion/styled';

import { Icon } from '../../../shared/components/icon';

const InfoStyled = styled.div`
  position: relative;
  padding-left: 55px;
  /* width: 480px; */
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.4);
  padding-bottom: 20px;
`;

const IconStyled = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 99px;
  width: 36px;
  height: 36px;
  text-align: center;
  left: 0;
  top: 30%;
  transform: translateY(-50%);
  svg {
    position: absolute;
    fill: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

interface InfoProps {
  text: JSX.Element;
  icon: typeof React.Component;
}

export const Info: React.FunctionComponent<InfoProps> = (props: InfoProps) => {
  const { text, icon } = props;
  const SVG = icon;
  return (
    <InfoStyled>
      <IconStyled>
        <Icon height={24} width={24}>
          <SVG />
        </Icon>
      </IconStyled>
      {text}
    </InfoStyled>
  );
};
