import styled from '@emotion/styled';
import React, { useEffect } from 'react';

import { HeaderSmall } from '../../../../shared/composites/headerSmall';
import { Background } from '../../../../shared/components/background';
import { Content } from '../../../../shared/components/content';
import { Section } from '../../../../shared/components/section';
import { Waves } from '../../composites/waves';
import { ReactProps } from '../../../../shared/core/interfaces';

const SplashStyled = styled.div`
  opacity: 1;
  transition: opacity 0.7s;
  &.hide {
    opacity: 0;
  }
`;

interface SplashProps {
  label?: string;
  transitionHandler?: () => void;
}

export const Splash: React.FunctionComponent<SplashProps & ReactProps> = (
  props: SplashProps & ReactProps
) => {
  const { label, className, transitionHandler } = props;
  let container: HTMLDivElement | null;

  useEffect(() => {
    container?.addEventListener('transitionend', () => {
      if (transitionHandler) {
        transitionHandler();
      }
    });
  });

  return (
    <React.Fragment>
      <Background />
      <SplashStyled
        id="splash"
        ref={(node): HTMLDivElement | null => (container = node)}
        className={className}
      >
        <Waves side="both" />
        <Section>
          <Content>
            <HeaderSmall label={label} />
          </Content>
        </Section>
      </SplashStyled>
    </React.Fragment>
  );
};
