import React from 'react';
import styled from '@emotion/styled';

import logo from '../assets/images/vision-project.png';

const HeaderStyled = styled.div`
  width: 415px;
  img {
    width: 100%;
  }
`;

export const Header: React.FunctionComponent = () => {
  return (
    <HeaderStyled>
      <img src={logo} />
    </HeaderStyled>
  );
};
