import React from 'react';
import styled from '@emotion/styled';
import { ReactProps } from '../core/interfaces';

interface SectionProps {
  id?: string;
  freeHeight?: boolean;
  freeAlign?: boolean;
  textAlign?: 'left' | 'center' | 'right';
}

const SectionStyled = styled.section<SectionProps>`
  position: relative;
  display: flex;
  height: auto;
  min-height: ${({ freeHeight }): string => (freeHeight ? 'initial' : '100vh')};
  flex-basis: 100vh;
  width: 100%;
  padding: 0 160px;
  box-sizing: border-box;
  align-items: ${({ freeAlign }): string => (freeAlign ? 'initial' : 'center')};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  &.white-background {
    background: linear-gradient(
      transparent 0%,
      rgba(255, 255, 255, 0.1) 0.5%,
      transparent 100%
    );
  }
`;

export const Section: React.FunctionComponent<SectionProps & ReactProps> = ({
  id,
  freeHeight,
  freeAlign,
  textAlign,
  children,
  className,
}: SectionProps & ReactProps) => {
  return (
    <SectionStyled
      id={id}
      freeHeight={freeHeight}
      freeAlign={freeAlign}
      textAlign={textAlign}
      className={className}
    >
      {children}
    </SectionStyled>
  );
};
