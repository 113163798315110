import { action, computed, observable } from 'mobx';
import { createContext } from 'react';
import Cookies from 'universal-cookie';

import { Logger } from '../../../shared/logger';
import {
  Message,
  onMessageActiveData,
  onMessageCompletionData,
  onMessageProgressData,
  onMessageStudyDetailsRequest,
  onMessageCameraPermissions,
  onMessageGetInfo,
} from '../core/message';
import { getChromeExtId } from '../core/utils';

class ExtensionStore {
  private chromePort: chrome.runtime.Port;

  @observable
  private _progress = 0;

  @observable
  private _duration = 0;

  @observable
  private _code = '';

  @observable
  private _connecting = true;

  @observable
  private _connected = false;

  @observable
  private _active = false;

  @observable
  private _wasExtendedOnLaunch = false;

  @observable
  private _cameraPermissions = 'prompt'; // default value for camera permissions

  connectToExtension(): void {
    const getExtensionConnection: NodeJS.Timeout = global.setInterval(() => {
      const extensionId: string | null = getChromeExtId();
      if (extensionId) {
        clearInterval(getExtensionConnection);
        try {
          this.chromePort = chrome.runtime.connect(extensionId);
          Logger.info(
            `establishing connection pipeline with extension \n   id : ${extensionId}`
          );
          // Create a listener to receive messages sent by Vision Project chrome extension
          this._connected = true;
          this.chromePort.onMessage.addListener(this.onExtentionMethod);
          this.chromePort.onDisconnect.addListener(this.connectToExtension);
          this.requestActivated();
        } catch {
          window.location.reload();
        }
      } else {
        Logger.warn('failed to detect extension');
        this._connected = false;
        global.clearInterval(getExtensionConnection);
      }
    }, 1000);
  }

  private onExtentionMethod = (message: Message): void => {
    // Call onMessageProgressData in core/message.ts, the method acts as a type check and will call the handler
    // only if the message format match ProgressData
    onMessageProgressData(message, (data) => {
      //Call store set action, it will trigger re-render on all observer controllers
      this.setProgress(data.progress);
      this.setDuration(data.duration);
    });
    onMessageStudyDetailsRequest(message, () => {
      const cookies = new Cookies();
      this.chromePort.postMessage({
        pxyz: {
          data: cookies['vision-study'],
        },
      });
    });
    onMessageCompletionData(message, (data) => {
      if (data.code != '') {
        Logger.info(
          `received completion code from extension \n   code : ${data.code}`
        );
        this.setCode(data.code);
      }
    });
    onMessageActiveData(message, (data) => {
      this._connecting = false;
      this._active = data.isActive;
      Logger.info(
        `received extension active status ${this._active} & connnecting ${this._connecting}`
      );
    });
    onMessageCameraPermissions(message, (data) => {
      if (data.cameraPermissions) {
        Logger.info('camera permission received', data.cameraPermissions);
        this.setCameraPermissions(data.cameraPermissions);

        if (data.cameraPermissions !== 'granted') {
          window.location.href = `chrome-extension://${getChromeExtId()}/ui/index.html#/camera`;
        }
      }
    });
    onMessageGetInfo(message, (data) => {
      this._wasExtendedOnLaunch = data.wasExtendedOnLaunch;
    });
  };

  @computed
  get progress(): number {
    return this._progress;
  }

  @action
  setProgress(value: number): void {
    this._progress = value;
  }

  @computed
  get duration(): number {
    return this._duration;
  }

  @action
  setDuration(value: number): void {
    this._duration = value;
  }

  @action
  sendDetails(): void {
    if (this.chromePort) {
      const cookies = new Cookies(document.cookie);
      Logger.info(`sending study details to extension`);
      this.chromePort.postMessage({
        pxyz: {
          data: {
            duration: cookies.get('vision-study')['duration'],
            feedId: cookies.get('vision-study')['feedId'],
            feedUrlPath: cookies.get('vision-study')['feedUrlPath'],
            adCampaignId: cookies.get('vision-study')['adCampaignId'],
            userId: cookies.get('vision-study')['userId'],
            type: cookies.get('vision-study')['type'],
            platformId: cookies.get('vision-study')['platformId'],
            vpDebugMode: cookies.get('vision-study')['vpDebugMode'],
            showHalo: cookies.get('vision-study')['showHalo'],
            targetSize: cookies.get('vision-study')['targetSize'],
            targetCount: cookies.get('vision-study')['targetCount'],
          },
        },
      });
    }
  }

  requestReload(): void {
    if (this.chromePort) {
      this.chromePort.postMessage({ pxyz: { data: { reload: true } } });
    }
  }

  @computed
  get code(): string {
    return this._code;
  }

  @action
  setCode(value: string): void {
    this._code = value;
  }

  @computed
  get connected(): boolean {
    return this._connected;
  }

  @action
  setConnected(value: boolean): void {
    this._connected = value;
  }

  @computed
  get active(): boolean {
    return this._active;
  }

  @action
  setActive(value: boolean): void {
    this._active = value;
  }

  @computed
  get connecting(): boolean {
    return this._connecting;
  }

  @action
  setConnecting(value: boolean): void {
    this._connecting = value;
  }

  @action requestCameraPermissions(): void {
    if (this.chromePort) {
      Logger.info(`sending request for camera permissions`);
      this.chromePort.postMessage({
        pxyz: {
          data: {
            request: 'cameraPermissions',
          },
        },
      });
    }
  }

  @action requestCode(): void {
    if (this.chromePort) {
      Logger.info(`sending request for completion code`);
      this.chromePort.postMessage({
        pxyz: {
          data: {
            request: 'code',
          },
        },
      });
    }
  }

  @action requestActivated(): void {
    if (this.chromePort) {
      Logger.info(`sending request for active state`);
      this.chromePort.postMessage({
        pxyz: {
          data: {
            request: 'active',
          },
        },
      });
    }
  }

  @action
  setCameraPermissions(value: string): void {
    this._cameraPermissions = value;
  }

  @computed
  get cameraPermissions(): string {
    return this._cameraPermissions;
  }

  get wasExtendedOnLaunch(): boolean {
    return this._wasExtendedOnLaunch;
  }
}

const store = new ExtensionStore();

store.connectToExtension();

export default createContext(store);
